.radio-button-container {
  padding-top: 0px;
  margin-top: 20px;
  position: absolute;
}

.text-container {
  float: left;
  width: 40%;
  line-height: 1.6;
  color: var(--text-color);
  margin-top: 14vh;
  padding-top: 0.9vh;
  border-top: 2px solid gray;
}

.map-container {
  margin-top: 0px;
  width: 55%;
  float: right;
  margin-right: 1%;
}

.heading {
  font-weight: bold;
  font-size: 2.8vw;
  overflow-wrap: break-word;
  margin-bottom: 0.65vh;
}

.radio-button {
  font-weight: 500;
  font-size: 18px;
  color: var(--text-color);
}

.radio-button:hover {
  color: var(--hover-color);
}

.question-logo {
  width: 25px;
  right: 20px;
  position: absolute;
  opacity: 0.8;
}

.hover-text {
  display: none;
  position: absolute;
  right: 50px;
  font-size: 14px;
  background-color: rgb(230,230,230);
  border: 2px solid black;
  border-radius: 5px;
  padding: 8px;
} 

.question-logo:hover + .hover-text {
  display: block;
}