.navbar-distributed {
  width: 100%;
  background-color: var(--navbar-background-color);
  display: flex;
  vertical-align: center;
  font-size: var(--navbar-font-size);
}

.navbar-distributed a {
  float: left;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 35px;
  text-decoration: underline;
  color: var(--navbar-text-color);
}

.navbar-distributed a:hover {
  text-decoration: underline;
  color: var(--hover-color);
}

.navbar-distributed p {
  margin-left: auto;
  margin-top: auto;
  margin-right: 15px;
  font-size: 25px;
  color: gray;
}

#Mittelstand-Logo {
  margin: 20px;
  height: 50px;
}
