:root {
  --standart-font-family: Arial;

  --text-font-size: 1.1vw;
  --heading-font-size: 1.7vw;
  --navbar-font-size: 2.8vh;
  --annotation-font-size: 1.8vh;

  --header-color: rgb(184,9,0);
  --text-color: #282c34;
  --navbar-text-color: #333;
  --hover-color: darkblue;
  --annotation-color: rgb(130,130,130);

  --app-background-color: white;
  --navbar-background-color: rgb(237, 237, 237);
}

body {
  background-color: var(--app-background-color);
  color: var(--text-color);
  font-size: var(--text-font-size);
  font-family: var(--standart-font-family);
  font-size: var(--text-font-size);
}

.Page {
  margin-top: 1.1vh;
  margin-left: 3vw;
  margin-left: 3vw;
  margin-top: 1.1vh;
  white-space: pre-wrap;
}

.Page h1 {
  color: var(--header-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
} 

