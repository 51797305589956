#image {
    float: left;
    padding: 5px;
    margin-right: 5px;
    width: 11.5vw;
    margin-bottom: 10px;
}

#name {
    font-weight: bold;
    font-size: 1.3vw;
}