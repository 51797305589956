.footer-distributed {
  background-color: var(--navbar-background-color);
  width: 100%;
  padding: 30px 30px;
  display: inline-block;
  font-size: var(--navbar-font-size);
}

.footer-links {
  display: inline-block;
}

.footer-link {
  margin-right: 20px;
  text-decoration: underline;
  color: var(--navbar-text-color);
}

.footer-right {
  float: right;
}

.footer-right a {
  display: inline-block;
}

.footer-distributed p.footer-links a:hover{
  color: var(--hover-color);
  text-decoration: underline;
}

#footer-logo {
  height: 110px;
}

.copyright {
  color: var(--annotation-color);
  margin: 0;
  margin-top: 2px;
  font-size: var(--annotation-font-size);
}

.copyright a {
  color: var(--annotation-color);
  text-decoration: underline;
  font-size: var(--annotation-font-size);
}